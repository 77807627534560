import React from "react"
import styles from "./social-button.module.scss"
import Icon from "../../icon/icon"
import { getIcon } from "../../../utils"

export const SocialButton = ({ text, name, icon, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={styles.socialBtn}
    >
      <div className={styles.socialBtnText}>
        <span>{text}</span>
        <br />
        <b>{name}</b>
      </div>
      <Icon name={icon} icon={getIcon(icon)} />
    </a>
  )
}
