import React from "react"
import styles from "./home.banner.module.scss"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { SocialButton } from "./social-button"
import { useSiteMetadata } from "../../../hooks"

export default () => {
  const {
    contacts: { instagram, facebook, youtube, telegramChannel },
  } = useSiteMetadata()
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div className={styles.homeBanner}>
      <Img
        fluid={file.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        className={styles.homeBannerImg}
        title="Гготові будинки"
        fadeIn
      />
      <div className={styles.homeBannerContent}>
        <h1 className={styles.homeBannerText}>
          Готові
          <br />
          Будинки
        </h1>
        <div className={styles.homeBannerSocials}>
          <SocialButton
            name="YouTube"
            text="Офіційний канал"
            icon="youtube"
            link={youtube}
          />
          <SocialButton
            name="Telegram"
            text="Офіційний канал"
            icon="telegram"
            link={telegramChannel}
          />
          <SocialButton
            name="Instagram"
            text="Офіційна сторінка"
            icon="instagram"
            link={instagram}
          />
          <SocialButton
            name="Facebook"
            text="Офіційна сторінка"
            icon="facebook"
            link={facebook}
          />
        </div>
      </div>
    </div>
  )
}
