import React from "react"
import styles from "./telegram.banner.module.scss"
import { useSiteMetadata } from "../../../hooks"
import Icon from "../../icon/icon"
import { getIcon } from "../../../utils"

export default () => {
  const {
    contacts: { telegramChannel },
  } = useSiteMetadata()

  return (
    <div className={styles.telegramBanner}>
      <p className={styles.telegramBannerText}>
        Дізнавайтеся
        <br />
        про нові будинки першими
      </p>
      <div>
        <a
          href={telegramChannel}
          className={styles.telegramBannerBtn}
          target="_blank"
          rel="noreferrer"
        >
          <span>Підписатися</span>
          <div className={styles.iconWrapper}>
            <Icon name="telegram" icon={getIcon("telegram")} />
          </div>
        </a>
      </div>
    </div>
  )
}
