import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import { CityHousesSwiperCard } from "../card"

import styles from "./city-houses-swiper.module.scss"
import "./city-houses-swiper.scss"
export default function CityHousesSwiper({ cities }) {
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        style={{
          "--swiper-navigation-color": "#d92228",
          "--swiper-pagination-color": "#d92228",
        }}
        keyboard={{ enabled: true }}
        className="city-houses-swiper"
        spaceBetween={15}
        pagination={{
          clickable: true,
        }}
        navigation={{
          disabledClass: "disabled-city-swiper-nav-class",
        }}
        modules={[Pagination, Navigation]}
      >
        {cities.map(city => (
          <SwiperSlide className={styles.swiperSlide} key={city.slug}>
            <CityHousesSwiperCard city={city} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
