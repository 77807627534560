import React, { useMemo } from "react"
import { useHousesList } from "../hooks"
import Layout from "../components/layout"
import { HousesGoogleMap } from "../components/map"
import "../styles/common/houses-list.scss"
import { calcCityHouses } from "../utils"
import CityHousesSwiper from "../components/swiper/city-houses-swiper"
import { HomeBanner, TelegramBanner } from "../components/banner"

const IndexPage = ({ location }) => {
  const houses = useHousesList(1000)
  const cityHouses = useMemo(() => calcCityHouses(houses), [houses])

  const title = "Купити будинок з ремонтом чи під чистове оздоблення"
  const description =
    "Наші знання - ваша власність. Будуємо для своїх клієнтів зручні, якісні будинки в м. Києві та Київській області."

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      location={location}
      HeaderBanner={HomeBanner}
    >
      <p>
        <b>Ласкаво просимо на наш веб-сайт</b>, де ви знайдете готові цегляні
        будинки в різних локаціях Київської області. Наша компанія пропонує
        швидке та просте придбання готового будинку, який повністю задовольнить
        ваші потреби та бажання, без необхідності самому займатися будівництвом,
        витрачаючи власний час та зусилля.
      </p>
      <h3>Будинки в продажі:</h3>
      <CityHousesSwiper cities={cityHouses} />
      <TelegramBanner />
      <h3>Карта об'єктів:</h3>
      <HousesGoogleMap houses={houses} />
    </Layout>
  )
}

export default IndexPage
